h1,h2,h3,h4,h5{
  color: #BC4077;
  font-weight: bolder;
  font-family: Helvetica, sans-serif
}

.app{
  padding: 30px;
  text-align: center;
}
.center-block{
  max-width: 300px;
}
.flex-center{
  display: flex;
  justify-content: center;
}

.fb-button{
  background-color: #3b5998;
  color: #fff;
}