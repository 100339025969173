.cup-round{

}

.cup-route-create-wrap{
    display: flex;
    justify-content: center;
}
.cup-route-create{
    width: 370px;

    padding: 5px 20px;
    border: 1px #ccc solid;

    margin-bottom: 5px;

    border-radius: 16px;
    overflow: hidden;
}

.cup-route-list-wrap{
    display: flex;
    justify-content: center;
}
.cup-route-row{
    margin-bottom: 5px;
    border: 1px #ccc solid;

    border-radius: 16px;
    overflow: hidden;
}
.cup-route-overview{
    display: flex;
    justify-content: left;
    align-items: center;
}
.cup-route-details{
    border-top: 1px #ccc solid;
    padding: 15px;
    padding-top: 5px;
}
.cup-route-details-ticklist{

}

.cup-route-col{
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 30px;
    padding: 5px 20px;
    width: 74px;
    height: 54px;

    border-right: 1px #ccc solid;
}
.cup-route-col:first-child{

}
.cup-route-col:last-child{
    border-right: 0;
}
.cup-route-tick-toggle:hover{
    cursor: pointer;
}
.cup-route-row-expander{
    cursor: pointer;
}

.cup-scoreboard-big{
    display: flex;
    justify-content: center;
    text-align: center;
}
.cup-scoreboard-wrapper{
    display: flex;
    justify-content: center;

    margin-bottom: 5px;
}
.cup-scoreboard{
    width: 370px;
}
.cup-scoreboard-color-title{
    padding: 12px;
    font-size: 22px;
    font-weight: bold;

    box-shadow: -2px 2px 2px 0px rgba(0,0,0,0.2);
    border-radius: 16px;
    overflow: hidden;
}
.cup-scoreboard-color-list{

}
.cup-scoreboard-row{
    display: flex;
    justify-content: center;
    padding: 10px 0;
}
.cup-scoreboard-col{

}
.cup-scoreboard-col-place{
    flex: 1;
}
.cup-scoreboard-col-name{
    flex: 6;
}
.cup-scoreboard-col-score{
    flex: 2;
}